<template>
  <div id="search">
    <!-- FILTROS -->
    <div
      id="search-page"
      class=""
    >
      <b-row class="categoryFilter">
        <b-col
          sm="5"
          class="categoryFilter"
        >
          <b-overlay
            variant="transparent"
            :show="searching"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <v-select
              id="catSelect"
              v-model="catSelc"
              :no-options-text="$t('SearchCategoryFilter')"
              :searchable="!searching"
              :search-input="searchName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('categoryFilter')"
              :options="categoriesSearch"
              :disabled="!seleccionar && !orphan ? false : true"
              :open-on-focus="false"
              @search="delayMethod"
            />
          </b-overlay>
        </b-col>

        <b-col class="align-items-center">
          <b-form-checkbox
            v-model="orphan"
            switch
            :disabled="!seleccionar && isEmpty(catSelc) ? false : true"
          >
            {{ $t('orphanProduct') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-2">
        <b-col md="3" />

        <b-col md="2">
          <b-form-select
            v-if="seleccionar"
            v-model="state"
            :options="optionsStatus"
          />
        </b-col>
        <b-col
          v-if="seleccionar"
          md="2"
        >
          <b-button
            v-if="selectProduct.length > 0 && state != null"
            variant="success"
            @click.stop.prevent="status()"
          >
            {{ $t('dataGeneric.change') }}
          </b-button>
          <b-button
            v-else
            disabled
          >
            {{ $t('dataGeneric.change') }}
          </b-button>
        </b-col>
        <b-col
          v-if="seleccionar"
          md="2"
        >
          <b-dropdown
            right
            variant="primary"
            class="mr-1"
            :text="$t('resources.tableHeader.actions')"
          >
            <b-dropdown-item
              :disabled="selectProduct.length == 0 ? true : false"
              @click.stop.prevent="showModal(0)"
            >
              {{ $t('addToCategory') }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="isEmpty(catSelc) || selectProduct.length == 0 ? true : false
              "
              @click.stop.prevent="showModal(1)"
            >
              {{ $t('moveFromCategory') }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="isEmpty(catSelc) || selectProduct.length == 0 ? true : false
              "
              @click.stop.prevent="deleteFromCategory()"
            >
              {{ $t('removeFromCategory') }}
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="selectProduct.length == 0 ? true : false"
              variant="danger"
              @click.stop.prevent="deleteContent()"
            >
              {{ $t('dataGeneric.delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox
            v-if="seleccionar"
            v-model="todos"
            switch
          >
            {{ $t('slider.allSelect') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!-- CONTENIDOS -->
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon icon="GridIcon" />
            <span>{{ $t('category.card') }}</span>
          </template>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selectProduct"
            name="some-radios"
          >
            <b-overlay
              variant="transparent"
              :show="show"
            >
              <b-row>
                <b-col>
                  <span v-if="search && search.length != 0">{{ $t('resultFounds', { length: search.length }) }}</span>
                  <span v-else>{{ $t('subscriptions.notFound') }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="folder in search"
                  :id="folder.node.id"
                  :key="folder.node.id"
                  class="mt-5"
                  md="6"
                  lg="3"
                  xl="3"
                  sm="12"
                >
                  <b-card
                    :id="folder.node.id"
                    class="carpeta"
                    no-body
                    @click.stop.prevent="!seleccionar ? routeToContentView(folder.node.id) : clickCheck(folder.node)"
                  >
                    <b-img-lazy
                      :src="buildImageUrl(folder)"
                      class="card-img-top cursor-pointer"
                      img-alt="Card image"
                      @error="errorImg"
                    />
                    <b-card-header>
                      <div id="acortarPadre">
                        <b-card-title
                          id="acortar"
                          class="mb-25"
                        >
                          <span
                            v-if="folder.node.state == 'GRE'"
                            class="mr-50 bullet bullet-success bullet-sm"
                          />
                          <span
                            v-if="folder.node.state == 'YEW'"
                            class="mr-50 bullet bullet-warning bullet-sm"
                          />
                          <span
                            v-if="folder.node.state == 'RED'"
                            class="mr-50 bullet bullet-danger bullet-sm"
                          />
                          <span
                            v-if="folder.node.state == 'BCK'"
                            class="mr-50 bullet bullet-dark bullet-sm"
                          />
                          {{ folder.node.name }}
                        </b-card-title>
                        <b-card-text class="mb-0">
                          {{ $t('producto') }}
                        </b-card-text>
                      </div>
                    </b-card-header>
                    <b-card-body>
                      <div
                        v-if="!seleccionar"
                        class="actionsBar right"
                      >
                        <div class="action">
                          <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template #button-content>
                              <feather-icon
                                color="white"
                                icon="MoreVerticalIcon"
                                size="20"
                              />
                            </template>
                            <b-dropdown-item @click.stop.prevent="routeToEditView(folder.node.id)">
                              <feather-icon
                                icon="Edit2Icon"
                                size="18"
                                class="cursor-pointer"
                              />
                              {{ $t('Edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click.stop.prevent="deleteProduct(folder.node.id)">
                              <feather-icon
                                icon="Trash2Icon"
                                size="18"
                              />
                              {{ $t('dataGeneric.delete') }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                      <div
                        v-else
                        class="actionsBar left"
                      >
                        <div class="action ml-1">
                          <b-form-checkbox
                            disabled
                            :value="folder.node.id"
                          />
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form-checkbox-group>
        </b-tab>
      </b-tabs>

      <div
        v-if="hasMore"
        class="d-flex justify-content-center align-self-center mt-2"
      >
        <small>{{ $t('subscriptions.scrollProd') }}</small><feather-icon
          icon="ArrowDownIcon"
          size="20"
          class="ml-50"
        />
      </div>

      <b-modal
        ref="my-modal"
        size="xl"
        hide-footer
        :title="$t('modal.category')"
        @hide="cleanData"
      >
        <div class="d-block text-center">
          <category-selector
            v-if="mov == 0"
            :type="true"
            :especial="true"
            :sponsor="isSponsor"
            :menu-in="isPod ? 'POD' : null"
            :menu-ex="!isPod ? 'POD' : null"
            :columns="categorySelectorTable"
            @confirm-selection="addToCategory"
          />
          <category-selector
            v-else
            :type="true"
            :especial="true"
            :sponsor="isSponsor"
            :menu-in="isPod ? 'POD' : null"
            :menu-ex="!isPod ? 'POD' : null"
            :columns="categorySelectorTable"
            @confirm-selection="moveToCategory"
          />
        </div>
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click.stop.prevent="hideModal"
        >
          {{ $t("dataGeneric.close") }}
        </b-button>
      </b-modal>
      <div ref="infiniteScroll" />
    </div>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  utf8ToB64, checkRelationOrder, removeRelation, removeResources, showToast, checkContents, cleanRelationContents,
  isEmpty, messageError,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import vSelect from 'vue-select'
import axios from '@axios'

import {
  BCol,
  BImgLazy,
  BRow,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BTab,
  BTabs,
  BCardTitle,
  BOverlay,
  BFormSelect,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import CategorySelector from '../../common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  name: 'Search',
  components: {
    BDropdown,
    BDropdownItem,
    BTab,
    BTabs,
    BCol,
    BFormCheckbox,
    vSelect,
    BImgLazy,
    BModal,
    BRow,
    BFormCheckboxGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BCardTitle,
    CategorySelector,
    BCardText,
    BButton,
    BOverlay,
  },
  props: {
    searchInput: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEmpty,
      state: null,
      optionsOrder: [
        { value: true, text: this.$t('orderByRecents') },
        { value: false, text: this.$t('orderByOld') },
      ],
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('editContent.status') },
        { key: 'emision', label: this.$t('emision') },
        { key: 'date_publication', label: this.$t('contents.datePublication') },
        { key: 'date_final', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
      search: [],
      active: false,
      show: false,
      searching: false,
      todos: false,
      single: 'single',
      isSponsor: false,
      userData: getUserData(),
      selected: null,
      categoryName: null,
      categoryId: null,
      catContentId: [],
      options: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      catSelc: null,
      optionsStatus: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      seleccionar: false,
      orphan: false,
      selectProduct: [],
      headers: {},
      infoProduct: [],
      catTableData: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      isPod: false,
      mov: 0,
      changed: false,
      isLoading: false,
      hasMore: true,
      categoriesSearch: [],
      searchName: '',
      page: 1,
      typingTimer: null,
      doneTypingInterval: 500,
    }
  },
  watch: {
    catSelc() {
      this.onKeyUp(true)
    },
    selected() {
      this.onKeyUp(true)
    },
    orphan() {
      this.onKeyUp(true)
    },
    seleccionar() {
      this.selectProduct = []
      this.infoProduct = []
      this.todos = false
    },
    todos() {
      if (this.todos) {
        this.search.forEach(element => {
          this.selectProduct.push(element.node.id)
          this.infoProduct.push(element.node)
        })
      } else {
        this.selectProduct = []
        this.infoProduct = []
      }
    },
    orderBy() {
      this.onKeyUp(true)
    },
    searchInput() {
      this.onKeyUp(true)
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    window.addEventListener('scroll', this.handleScroll)
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
    this.onKeyUp(true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  activated() {
    this.$route.meta.keepAlive = true
  },
  methods: {
    searchCategorySelect() {
      this.searchName += ''
      if (this.searchName.trim().length >= 3) {
        this.allCategory()
      }
    },
    delayMethod() {
      /* IMPORTANT! Do not change the event, you must access target.value to get the input value, and not from keyup.  */
      // eslint-disable-next-line no-restricted-globals
      this.searchName = event.target.value

      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.searchCategorySelect() // Envuelve la llamada dentro de una función de flecha
      }, this.doneTypingInterval)
    },
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.getProducts()
      }
    },
    cleanData() {
      if (!this.seleccionar && !this.changed) {
        this.selectProduct = []
        this.infoProduct = []
      }
    },
    getProducts() {
      const { headers } = this
      this.show = true
      this.isLoading = true
      axios
        .post('', {
          query: `
         query{
          allProducts(
          search:"${this.searchInput}", 
          orphan:${this.orphan},
          first:12,
          offset:${12 * (this.page - 1)},
          client:"${this.userData.profile.client.id}"
          ${!isEmpty(this.catSelc) ? `,categories:"${utf8ToB64(`id:${this.catSelc.value}`)}"` : ''}
          ){
            pageInfo {
            hasNextPage            
          }
          totalCount
          edges {
            node {
              id
              name
              imageUrl
              image
              state
              isActive              
              categories ${!isEmpty(this.catSelc) ? `(id : "${this.catSelc.value}")` : ''
}{
                totalCount
                edges {
                  node {
                    id
                    name
                    isFinal
                  }
                }
              }

            }
          }
        }
      }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)
          const srch = response.data.data.allProducts.edges
          if (response.data.data.allProducts.pageInfo.hasNextPage) {
            this.search = [...this.search, ...srch]
            this.page += 1
          } else {
            this.search = [...this.search, ...srch]
            this.hasMore = false
          }
          this.isLoading = false
          this.show = false
        }).catch(res => {
          console.log(res)
        })
    },
    routeToEditView(id) {
      this.$router.push({ name: 'edit-products', params: { id } })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    showModal(type) {
      let difference = false
      if (this.infoProduct.length > 0) {
        let pod = 0

        this.infoProduct.forEach(element => {
          if (element.type === 'POD' || element.type === 'AUC') {
            pod += 1
          }
        })
        if (pod === this.infoProduct.length) {
          this.isPod = true
        } else if (pod === 0) this.isPod = false
        else difference = true
      }
      if (!difference) {
        this.mov = type
        this.$refs['my-modal'].show()
      } else {
        this.$swal({
          title: this.$t('incompatiblesProd'),
          text: this.$t('pod'),
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(() => {

        }).catch(() => {

        })
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    addToCategory(cat) {
      this.changed = true
      this.categoryId = cat.node.id
      this.categoryName = cat.node.name
      this.hideModal()

      const data = []
      this.selectProduct.forEach(element => {
        data.push(element)
      })
      cat.node.categoryContent.edges.forEach(element => {
        data.push(element.node.id)
      })

      const dataArr = new Set(data)

      const result = [...dataArr]

      axios
        .post('', {
          query: `
         mutation{
          updateCategory(id: "${this.categoryId}", input: { categoryContent: [${result}] }) {
            category{
              id
              name
              categoryContent(orderBy:"order,-created_at"){
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
          `,
        })
        .then(res => {
          messageError(res, this)

          checkRelationOrder(this.categoryId).then(() => {

          }).catch(err => {
            console.log(err)
          }) // Crea nueva relación

          cleanRelationContents(this.selectProduct).then(() => {
          }).catch(err => {
            console.log(err)
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('contentsAddToCat')} ${this.categoryName}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.selectProduct = []
          this.infoProduct = []
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('ErrorContentsAddToCat'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    allCategory() {
      const name = this.searchName
      // this.searchName = ""
      if (name.trim().length > 0) {
        this.searching = true
        axios
          .post('', {
            query: `
              query{
                allCategoryShop(name:"${name}",client: "${this.userData.profile.client.id}"
           
            ) {
                edges{
                  node{
                  id
                  name                  
                }
              }
            }
          }
            `,
          })
          .then(res => {
            messageError(res, this)

            this.catTableData = res.data.data.allCategoryShop.edges
            this.categoriesSearch = []
            this.catTableData.forEach(element => {
              this.categoriesSearch.push({ label: element.node.name, value: element.node.id })
            })
            this.searching = false
          }).catch(err => {
            console.log(err)
          })
      }
    },
    selectItem(node) {
      if (node.type === 'POD' || node.type === 'AUC') {
        this.isPod = true
      } else {
        this.isPod = false
      }
      this.selectProduct.push(node.id)
      this.infoProduct.push(node)
      this.showModal(0)
    },
    routeToContentView(folderId) {
      this.$router.push({ name: 'edit-products', params: { id: folderId } })
    },

    status() {
      let isActive = false
      switch (this.state) {
        case 'GRE':
          isActive = true
          break
        case 'YEW':
          isActive = true

          break
        case 'RED':
          isActive = false
          break
        case 'BCK':
          isActive = false
          break

        default:
          break
      }
      let query = 'mutation{'
      this.selectProduct.forEach((element, i) => {
        query += `
          m${i + 1}: updateContents(id: "${element}", input: { state: ${this.state
}, isActive: ${isActive}}) {
            content {
                  id
                  isActive
                }
              }
              `
      })
      query += '}'

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          this.onKeyUp(true)
        })
      let estado = ''
      switch (this.state) {
        case 'GRE':
          estado = this.$t('ecommerce.public')
          break
        case 'YEW':
          estado = this.$t('dataGeneric.internal')

          break
        case 'RED':
          estado = this.$t('ecommerce.NoPublic')
          break

        default:
          break
      }
      if (this.selectProduct.length >= 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              this.selectProduct.length === 1
                ? `${this.$t('statusContentCh')} ${estado}`
                : `${this.$t('statusContentsCh')} ${estado}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteProduct(id) {
      this.overlay = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = ''

          query = `
                mutation{
                  deleteProductsShop(id:"${id}") {
                    found
                    deletedId
                  }
                }`
          axios
            .post('', {
              query,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('deleteProduct'), 1, this)
              this.onKeyUp(true)
              this.overlay = false
            })
            .catch(res => {
              this.overlay = false
              showToast(this.$t('error'), 2, this)
              console.log(res)
            })
        } else {
          this.overlay = false
        }
      })
    },
    deleteContent(id = null) {
      if (id != null) {
        this.selectProduct = []
        this.infoProduct = []
        this.selectProduct.push(id)
      }
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.show = true
          let query = '{'
          this.selectProduct.forEach((element, i) => {
            query += `cont_${i
              + 1}: allContents(id:"${element}") {
                edges {
                  node {
                    id
                    name
                    contentResources {
                      totalCount
                      edges {
                        node {
                          id              
                          resource {
                            id
                            name
                            file {
                              id
                              name
                            }
                            resourceContent {
                              totalCount
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }      
                  }
                }
              }`
          })
          query += '}'
          axios
            .post('', {
              query,
            }).then(res => {
              messageError(res, this)

              const contentResources = res.data.data
              removeResources(contentResources).then(resultado => {
                if (resultado) {
                  this.$swal({
                    title: this.$t('code.title'),
                    text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('code.confirm'),
                    cancelButtonText: this.$t('dataGeneric.cancel'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(response => {
                    if (response.value) {
                      let query2 = 'mutation{'
                      resultado.id.forEach((element, i) => {
                        query2 += `
                                resource_${i + 1}: deleteResource(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                      })
                      if (resultado.file.length) {
                        resultado.file.forEach((element, i) => {
                          query2 += `
                                file_${i + 1}: deleteFile(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                        })
                      }
                      query2 += '}'
                      axios.post('', { query: query2 }).then(r => {
                        messageError(r, this)

                        this.delete()
                      }).catch(err => {
                        console.log(err)
                      })
                    } else {
                      this.delete()
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.delete()
                }
              }).catch(err => {
                console.log(err)
              })
            }).catch(err => {
              console.log(err)
            })
        }
      })
    },
    delete() {
      checkContents(this.selectProduct).then(() => {
        let query = 'mutation{'
        this.selectProduct.forEach((element, i) => {
          query += `a_${i
            + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(r => {
            messageError(r, this)

            this.selectProduct.forEach(element => {
              this.search = this.search.filter(item => item.node.id !== element)
            })
            this.selectProduct = []
            this.infoProduct = []

            showToast(this.$t('success'), 1, this)

            this.show = false
          })
          .catch(() => {
            showToast(this.$t('error'), 2, this)

            this.show = false
          })
      }).catch(err => {
        console.log(err)
      })
    },

    errorImg(e) {
      e.target.src = fileError
    },
    clickCheck(node) {
      const i = this.selectProduct.indexOf(node.id)
      const a = this.infoProduct.map(e => e.id).indexOf(node.id)

      if (i !== -1) {
        this.selectProduct.splice(i, 1)
        this.infoProduct.splice(a, 1)

        // element.classList.add("disable")
      } else {
        // element.classList.add("active")
        this.selectProduct.push(node.id)
        this.infoProduct.push(node)
      }
    },
    back() {
      window.history.back()
    },
    onKeyUp(pass = false) {
      let keycode = 0
      try {
        // eslint-disable-next-line no-restricted-globals
        keycode = event.keyCode || event.which
        // eslint-disable-next-line no-empty
      } catch (error) { }

      if ((keycode === 13 || pass) && !this.show) {
        this.search = []
        this.hasMore = true
        this.page = 1
        this.getProducts()
      }
    },
    moveToCategory(category) {
      this.categoryName = category.node.name
      this.categoryId = category.node.id
      this.hideModal()
      let query = `query
      {`
      for (let i = 0; i < this.selectProduct.length; i += 1) {
        query += `
        q${i + 1}: allContents(id:"${this.selectProduct[i]}"){
            edges {
              node {
                id
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          `
      }
      query += `
      }`

      axios
        .post('', { query }).then(res => {
          messageError(res, this)

          const deleteFilter = []
          let query2 = ` mutation
      {`
          for (let a = 0; a < this.selectProduct.length; a += 1) {
            const data = res.data.data[`q${a + 1}`].edges[0].node
            deleteFilter.push(data.id)

            const categoriasRes = []
            try {
              const categories = data.categories.edges.filter(
                element => element.node.id !== this.catSelc.value,
              )

              categories.forEach(item => categoriasRes.push(item.node.id))

              categoriasRes.push(this.categoryId)
            } catch (error) {
              console.log(error)

              categoriasRes.push(this.categoryId)
            }

            query2 += `
              m${a + 1}: updateContents(id:"${data.id}", input:{
              categories: [${categoriasRes}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }

                `
          }
          query2 += `
            }`
          axios
            .post('', { query: query2 }).then(result => {
              messageError(result, this)

              removeRelation(this.catSelc.value, this.selectProduct) // eliminamos relación anterior
              checkRelationOrder(this.categoryId).then(() => {
                this.onKeyUp(true)
                this.selectProduct = []
                this.infoProduct = []
              }).catch(() => {

              }) // Creamos nueva relación
            }).catch(err => {
              console.log(err)
            })
        }).catch(err => {
          console.log(err)
        })
    },
    onRowSelected(items) {
      this.$router.push({
        name: 'edit-products',
        params: { id: items.node.id },
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    deleteFromCategory() {
      this.$swal({
        title: this.$t('removeFromCat'),
        text:
          this.$t('sureOrphan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let query = ' query{'

            for (let i = 0; i < this.selectProduct.length; i += 1) {
              query += `
        q${i + 1}: allContents(id: "${this.selectProduct[i]}"){
            edges {
              node {
                id
                contentContentOrder
                            ${!isEmpty(this.catSelc)
    ? `(category:"${utf8ToB64(
      `category:${this.catSelc.value}`,
    )}")`
    : ''
} {
                totalCount
                  edges {
                    node {
                        id
                        order
                    category {
                          id
                          name
                        }
                      }
                    }
                  }
                          categories {
                            edges {
                              node {
                        id
                      }
                    }
                  }
                }
              }
            }
            `
            }
            query += '}'
            axios
              .post('', { query })
              .then(res => {
                messageError(res, this)

                let data = []
                let query2 = ' mutation{'

                for (let a = 0; a < this.selectProduct.length; a += 1) {
                  data = res.data.data[`q${a + 1}`].edges[0].node

                  const categoriasRes = []

                  try {
                    const categories = data.categories.edges.filter(
                      element => element.node.id !== this.catSelc.value,
                    )
                    categories.forEach(item => res.push(item.node.id))
                    // eslint-disable-next-line no-empty
                  } catch (error) { }

                  query2 += `
                    m${a + 1}: updateContents(id: "${data.id}", input: {
                        categories: [${categoriasRes}]
                      }) {
                        content{
                          id
                          name
                          client {
                            id
                            name
                          }
                          categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }
                      }

                      `
                }
                query2 += '} '

                axios
                  .post('', { query: query2 })
                  .then(r => {
                    messageError(r, this)

                    this.onKeyUp(true)
                  })
                  .catch(err => {
                    console.log(err)
                  })
                let notMutation = 0
                query = ' mutation{ '
                this.selectProduct.forEach((element, a) => {
                  data = res.data.data[`q${a + 1}`].edges[0].node
                  if (data.contentContentOrder.totalCount !== 0) {
                    query += `
                  m${a + 1}: deleteContentOrder(id: "${data.contentContentOrder.edges[0].node.id
} ") {
                        found
                        deletedId
                                }
                        `
                  } else {
                    notMutation += 1
                  }
                })
                query += '}'

                if (notMutation < this.selectProduct.length) {
                  axios
                    .post('', { query })
                    .then(r => {
                      messageError(r, this)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
              })
              .catch(res => {
                console.log(res)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    moveToCat() {
      this.show = true
      this.$swal({
        title: this.$t('code.title'),
        text:
          this.$t('contentsMoves'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showModal(2)
        } else {
          this.show = false
        }
      })
    },

  },
}
</script>

<style>
.card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

.actionsBar {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.carpeta img {
  height: 11vw;
  object-fit: cover;
}

.card-body {
  padding: 1rem;
}

.card-body h4 {
  font-size: 1rem !important;
}

.card-header {
  padding: 1rem;
}

.card-header h4 {
  font-size: 1rem !important;
}

.card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;

}

.right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.dropContainer {
  border: 3px dashed;
}

.list-group-item {
  transition: all 1s;
}

.active {
  border: white 1px;
}

.active {
  border: 0px;
}

.categoryFilter {
  margin-inline-start: auto;
  place-items: center;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}
</style>
